import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { FaroButtonContained } from "@components/common/faro-button-contained";
import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import { AutoCompleteMessage } from "@components/common/faro-text-field/faro-text-field-message";
import { LabelWithHelp } from "@components/common/label-with-help";
import { MembersAutocomplete } from "@components/common/members-autocomplete/members-autocomplete";
import { createMemberOption } from "@components/common/members-autocomplete/members-autocomplete-utils";
import { AutoCompleteMemberOption } from "@components/common/members-autocomplete/members-autocomplete-types";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useCompanyMembers } from "@hooks/use-company-members";
import { useToast } from "@hooks/use-toast";
import { Grid, Stack } from "@mui/material";
import { useAppParams } from "@router/router-helper";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { useAppDispatch } from "@store/store-helper";
import { addMembersToTeam, createTeam } from "@store/teams/teams-slice-thunk";
import { STACK_ERROR_SX } from "@styles/common-styles";
import { nounPluralize } from "@utils/data-display";
import { capitalizeFirstLetter } from "@utils/string-utils";
import { TeamEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useMemo, useState } from "react";
import { APITypes } from "@stellar/api-logic";
import {
  isNewEmailValid,
  MAX_INVITED_MEMBERS,
  shouldDisableSendInvite,
  validateInputChange,
  validateMembers,
} from "@pages/members/teams/teams-utils";

/** Renders the create button and dialog to create team */
export function CreateTeam(): JSX.Element {
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const coreApiClient = useCoreApiClient();
  const companyMembers = useCompanyMembers();
  const { companyId } = useAppParams();
  const { trackEvent } = useTrackEvent();

  const [teamName, setTeamName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<AutoCompleteMessage | undefined>();

  /** Flag whether the input field in the autocomplete component has a valid email */
  const [isMemberInputValid, setIsMemberInputValid] = useState<boolean>(false);

  /** Stores the ids of the users to be assigned as group managers */
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);

  /** Whether the create button should be disabled */
  const isCreateDisabled =
    shouldDisableSendInvite(selectedMemberIds, isMemberInputValid) ||
    teamName.trim().length < 1;

  /** Stores the members of the company in the format required by the autocomplete */
  const memberOptions: AutoCompleteMemberOption[] = useMemo(() => {
    return companyMembers.map((member) => createMemberOption({ member }));
  }, [companyMembers]);

  /** Open the dialog. */
  async function onOpenDialog(): Promise<void> {
    trackEvent({ name: TeamEvents.startCreateNewTeam });
    setIsDialogOpen(true);
  }

  /** Resets the dialog content, and close the dialog. */
  async function onCloseDialog(): Promise<void> {
    setTeamName("");
    setDescription("");
    setSelectedMemberIds([]);
    setMessage(undefined);
    setIsDialogOpen(false);
  }

  /** Triggered when the create button is clicked, creates the group and closes the dialog */
  async function handleConfirm(): Promise<void> {
    if (!companyId) {
      throw new Error("companyId is required");
    }

    setIsLoading(true);

    trackEvent({
      name: TeamEvents.createNewTeam,
      props: { numberOfMembers: selectedMemberIds.length },
    });

    try {
      // Create Team
      const team = await dispatch(
        createTeam({
          coreApiClient,
          companyId,
          teamName: teamName.trim(),
          description: description.trim(),
        })
      ).unwrap();

      /** Team creation success message */
      const responseMessage = `${capitalizeFirstLetter(
        TEAM_DISPLAY_NAME
      )} created successfully`;

      /** Check if there are selected members to add; if so, proceed to add them, otherwise show a success message. */
      if (selectedMemberIds.length > 0) {
        await handleAddMembers(team.id, responseMessage);
      } else {
        showToast({
          type: "success",
          message: responseMessage,
        });
      }
    } finally {
      setIsLoading(false);
      onCloseDialog();
    }
  }

  /**
   * Add the members to the team
   * @param teamId The id of the team
   * @param responseMessage The message to be displayed after adding the members
   * @returns Promise<void>
   */
  async function handleAddMembers(
    teamId: string,
    responseMessage: string
  ): Promise<void> {
    if (!companyId) {
      throw new Error("companyId is required");
    }
    try {
      // Add members
      const response = await dispatch(
        addMembersToTeam({
          coreApiClient,
          companyId,
          teamId,
          members: selectedMemberIds,
        })
      ).unwrap();

      // Show response messages after adding members
      // Errors of creating team is handled by error slice
      const additionalMessages: string[] = [];
      if (response.errorData) {
        response.errorData.forEach((error: { message: string }) => {
          additionalMessages.push(error.message);
        });
      }

      // The status of the response
      // success: When all the invites go fine
      // warning: When some of the invites goes fine and some are failed
      // error: When all the invites are failed
      showToast({
        type: response.status,
        message: responseMessage,
        description: additionalMessages.map((message, index) => (
          <li key={index}>
            <var>{message}</var>
          </li>
        )),
      });
    } catch (error) {
      handleErrorWithToast({
        id: `inviteMemberToTeam-${Date.now().toString()}`,
        title: `${responseMessage}. But couldn't invite the ${nounPluralize({
          shouldShowCounter: false,
          counter: selectedMemberIds.length,
          word: "member",
        })}`,
        error,
      });
    }
  }

  /** Stores the ids of the members of the company */
  const memberIds = useMemo(
    () =>
      companyMembers.map(
        (member) => member.id?.toLowerCase() ?? member.email.toLowerCase()
      ),
    [companyMembers]
  );

  function handleMemberSelect(members: APITypes.UserIdentity[]): void {
    const { message, isValid } = validateMembers(
      members,
      TEAM_DISPLAY_NAME,
      MAX_INVITED_MEMBERS,
      memberIds
    );

    setMessage(message);
    setIsMemberInputValid(isValid);
    setSelectedMemberIds(members);
  }

  /**
   * Function to be called when the input value changes
   * It sets the valid emails to the addedEmails state and removes them from the input value
   */
  function onInputChange(value: string): void {
    const { message, isValid } = validateInputChange(
      memberOptions,
      companyMembers,
      value,
      TEAM_DISPLAY_NAME
    );

    setMessage(message);
    setIsMemberInputValid(isValid);
  }

  return (
    <>
      {/* Button to open dialog */}
      <FaroButtonContained onClick={onOpenDialog}>
        New {TEAM_DISPLAY_NAME}
      </FaroButtonContained>

      {/* Create project dialog */}
      <FaroDialog
        title={`New ${TEAM_DISPLAY_NAME}`}
        open={isDialogOpen}
        confirmText="Create"
        isConfirmDisabled={isCreateDisabled}
        onConfirm={handleConfirm}
        isConfirmLoading={isLoading}
        onClose={onCloseDialog}
      >
        <Grid maxWidth="100%" width="70vw">
          {/* Team name */}
          <Stack sx={STACK_ERROR_SX}>
            <LabelWithHelp
              title={`${capitalizeFirstLetter(TEAM_DISPLAY_NAME)} name`}
              isRequired
            />
            <FaroSimpleTextField
              autoFocus={true}
              placeholder={`Enter ${TEAM_DISPLAY_NAME} name`}
              size="small"
              fullWidth
              value={teamName}
              onChange={(event) => setTeamName(event.target.value)}
            />
          </Stack>

          {/* Team members */}
          <Stack sx={STACK_ERROR_SX}>
            <MembersAutocomplete
              options={memberOptions}
              handleChange={handleMemberSelect}
              onInputChange={onInputChange}
              placeHolder="Enter name or email"
              message={message}
              validateNewOption={(email) =>
                isNewEmailValid(email, companyMembers)
              }
              // If one of the members is preselected, they should be added to the group by default.
              initialValue={memberOptions.filter(
                (memberOption) => memberOption.isPreselected
              )}
              labelTitle="Members"
              isRequired={false}
            />
          </Stack>

          {/* Team description */}
          <Stack sx={STACK_ERROR_SX}>
            <LabelWithHelp title="Description" />
            <FaroSimpleTextField
              autoFocus={false}
              placeholder="Enter description"
              size="small"
              fullWidth
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Stack>
        </Grid>
      </FaroDialog>
    </>
  );
}
