import { Box, Breakpoint, SvgIcon, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { cardProperties } from "@utils/ui-utils";
import { CSSProperties, FunctionComponent } from "react";
import { FaroTextButton } from "@components/common/faro-text-button";
import { IntegrationActions } from "@services/integrations-service/integrations-types";

interface Props {
  /** Icon of the application */
  icon: FunctionComponent;

  /** Name of the integration */
  title: string;

  /** Details about the integration */
  description: string;

  /** Callback function triggers when the action button is clicked */
  onIntegrationAction(): void;

  /** The text to be shown for action button */
  actionButtonText: IntegrationActions;
}

/** Width of the cards depending on the breakpoint */
const integrationCardWidth: {
  [key in Breakpoint]: CSSProperties["width"];
} = {
  xs: "100%",
  sm: "48%",
  md: "48%",
  lg: "32%",
  xl: "32%",
};

/** Renders the tiles card for the integrations */
export function IntegrationTile({
  icon,
  title,
  description,
  actionButtonText,
  onIntegrationAction,
}: Props): JSX.Element {
  function getButtonText(): string {
    switch (actionButtonText) {
      case "ConnectToWorkspace":
        return "Enable";

      case "DisconnectFromWorkspace":
        return "Disable";

      case "ConnectToProject":
        return "Connect";

      case "DisconnectFromProject":
        return "Disconnect";
    }
  }

  return (
    <Box
      component="div"
      sx={{
        width: integrationCardWidth,
        height: cardProperties.height,
        marginTop: cardProperties.marginTop,
        boxShadow: cardProperties.boxShadow,
        borderRadius: cardProperties.borderRadius,
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box
          sx={{
            marginBottom: "24px",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <SvgIcon
            inheritViewBox={true}
            component={icon}
            sx={{ width: "50px", height: "50px" }}
          />
          <Typography
            sx={{
              color: sphereColors.gray800,
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "600",
            }}
          >
            {title}
          </Typography>
        </Box>

        <Typography
          sx={{
            color: sphereColors.gray600,
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "400",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
          }}
        >
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          borderTop: `1px solid ${sphereColors.gray200}`,
          textAlign: "right",
        }}
      >
        <FaroTextButton
          onClick={onIntegrationAction}
          sx={{ fontWeight: 600, mt: "16px" }}
        >
          {getButtonText()}
        </FaroTextButton>
      </Box>
    </Box>
  );
}
