import ComingSoonIcon from "@assets/icons/new/coming-soon_60px.svg?react";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { useAppSelector } from "@store/store-helper";
import { isAlphaTestingEnabledSelector } from "@store/app/app-selector";
import { IntegrationSection } from "@pages/integrations/integration-section";
import { useWorkspaceIntegrations } from "@pages/integrations/use-workspace-integrations";
import { useState } from "react";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { IntegrationDialog } from "@pages/integrations/integration-dialog";
import { NoIntegration } from "@pages/integrations/no-integration";
import { IntegrationActions } from "@services/integrations-service/integrations-types";

/** Renders the integrations page in workspace level */
export function WorkspaceIntegrations(): JSX.Element {
  const isAlphaTestingEnabled = useAppSelector(isAlphaTestingEnabledSelector);
  const [selectedIntegrationId, setSelectedIntegrationId] =
    useState<SphereDashboardAPITypes.IntegrationId>(
      SphereDashboardAPITypes.IntegrationId.autodesk
    );

  const {
    allIntegrations,
    enableIntegration,
    disableIntegration,
    closeAuthorizationWindow,
    isIntegrationInProgressDialogOpen,
    isDisableIntegrationDialogOpen,
    setIsDisableIntegrationDialogOpen,
  } = useWorkspaceIntegrations();

  if (!isAlphaTestingEnabled) {
    return (
      <EmptyPage
        title="Coming soon"
        subtitle="This page is still under development and will be available soon"
        icon={ComingSoonIcon}
      />
    );
  }

  function onIntegrationActionClicked(
    integrationId: SphereDashboardAPITypes.IntegrationId,
    action: Extract<
      IntegrationActions,
      "ConnectToWorkspace" | "DisconnectFromWorkspace"
    >
  ): void {
    setSelectedIntegrationId(integrationId);
    action === "ConnectToWorkspace"
      ? enableIntegration(integrationId)
      : setIsDisableIntegrationDialogOpen(true);
  }

  return (
    <>
      {allIntegrations.enabledIntegrationIds.length === 0 ? (
        <NoIntegration subject="workspace" />
      ) : (
        <IntegrationSection
          title="Authorized apps"
          integrations={allIntegrations.enabledIntegrationIds}
          actionButtonText="DisconnectFromWorkspace"
          onIntegrationAction={(integrationId) =>
            onIntegrationActionClicked(integrationId, "DisconnectFromWorkspace")
          }
        />
      )}

      {allIntegrations.availableIntegrationIds.length !== 0 && (
        <IntegrationSection
          title="Available apps"
          integrations={allIntegrations.availableIntegrationIds}
          actionButtonText="ConnectToWorkspace"
          onIntegrationAction={(integrationId) =>
            onIntegrationActionClicked(integrationId, "ConnectToWorkspace")
          }
        />
      )}

      <IntegrationDialog
        selectedIntegrationId={selectedIntegrationId}
        inProgressDialog={{
          isOpen: isIntegrationInProgressDialogOpen,
          confirm: closeAuthorizationWindow,
        }}
        disableDialog={{
          isOpen: isDisableIntegrationDialogOpen,
          confirm: () => disableIntegration(selectedIntegrationId),
          cancel: () => setIsDisableIntegrationDialogOpen(false),
        }}
      />
    </>
  );
}
