import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { useCoreApiClient } from "@api/use-core-api-client";
import { BaseTeamProps } from "@custom-types/teams-types";
import { CircularProgress, Typography, Stack } from "@mui/material";
import { CommonStyles } from "@styles/common-styles";
import {
  DEFAULT_INPUT_FONT_SIZE,
  inputPlaceholderItalicSx,
} from "@styles/common-styles";
import { useEffect, useState } from "react";
import { getTeamAvatarInitials } from "@utils/team-utils";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { updateTeamDetails } from "@store/teams/teams-slice-thunk";
import { useAppDispatch } from "@store/store-helper";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { TeamEvents } from "@utils/track-event/track-event-list";
import { closeDetailPanel } from "@store/teams/teams-slice";
import { sphereColors } from "@styles/common-colors";

/** Maximum number of rows for markup's detail input field */
const MAX_ROW = 7;

/** Maximum number of characters for markup's detail */
const MAX_INPUT_LENGTH = 500;

export function TeamSidePanelDescription({ team }: BaseTeamProps): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  /**
   * This method it will update the description field
   *
   * @param value the description value
   */
  async function updateDescription(value: string): Promise<string> {
    try {
      trackEvent({
        name: TeamEvents.changeTeamDetails,
        props: { newNameLength: value.length, attribute: "description" },
      });

      setIsUpdating(true);

      await dispatch(
        updateTeamDetails({
          coreApiClient,
          teamId: team.id,
          payload: { name: team.name, description: value },
        })
      );

      return value;
    } catch (error) {
      return team.description ?? "";
    } finally {
      setIsUpdating(false);
    }
  }

  useEffect(() => {
    return () => {
      dispatch(closeDetailPanel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      width={"320px"}
      padding={"20px"}
      paddingBottom={"50px"}
      border={CommonStyles.Borders.gray200Divider}
    >
      <Typography
        fontSize={"12px"}
        fontWeight={"bold"}
        color={sphereColors.gray800}
      >
        User group details
      </Typography>

      <Stack alignItems={"center"} marginTop={"23px"} mb={"34px"}>
        <SphereAvatar
          initials={getTeamAvatarInitials(team.name)}
          shouldHideWhiteRim={true}
          size="x-large"
        />
      </Stack>

      <Stack flexDirection={"row"} justifyContent={"end"}>
        {isUpdating && <CircularProgress size={"1rem"} sx={{ ml: "5px" }} />}
      </Stack>

      <FaroTextField
        maxRows={MAX_ROW}
        isDisabled={isUpdating}
        isReadOnly={false}
        isMultiline={true}
        shouldClearOnBlur={false}
        isAdornmentOnBottom={true}
        shouldShowCharacterCount={true}
        maxInputLength={MAX_INPUT_LENGTH}
        placeholder="Add description here"
        initialValue={team.description || ""}
        onConfirmed={(newValue) => updateDescription(newValue.trim())}
        fontSize={DEFAULT_INPUT_FONT_SIZE}
        sx={{
          ...inputPlaceholderItalicSx,
        }}
      />
    </Stack>
  );
}
