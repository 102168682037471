import { FaroButton, FaroButtonProps } from "@components/common/faro-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { Box } from "@mui/material";

type Props = Pick<FaroButtonProps, "onClick">;

/** Button to import ELS scan data */
export function ImportDataButton({ onClick }: Props): JSX.Element {
  return (
    <SphereTooltip
      boxProps={{
        sx: {
          display: "inline-block",
        },
      }}
      title={
        <div>
          <div style={{ fontWeight: "bold", fontSize: "14px" }}>
            Upload from your computer
          </div>
          Currently, only .gls files can be uploaded.
        </div>
      }
    >
      <FaroButton onClick={onClick}>
        <Box data-testid="sa-upload-data-button">
          Upload Data
        </Box>
      </FaroButton>
    </SphereTooltip>
  );
}
