import { SphereDashboardAPITypes } from "@stellar/api-logic";
import ProcoreIcon from "@assets/icons/integrations/procore-icon_50px.svg?react";
import AutodeskIcon from "@assets/icons/integrations/autodesk-icon_50px.svg?react";
import { FunctionComponent } from "react";

/** ID of each project integration */
export enum ProjectIntegrationId {
  procore = "procore",
  procoreObservations = "procore.observations",
  procoreRfis = "procore.rfis",
  autodesk = "autodesk",
  autodeskAccIssues = "autodesk-acc.issues",
  autodeskAccRfis = "autodesk-acc.rfis",
  autodeskBim360Issues = "autodesk-bim360.issues",
  autodeskBim360Rfis = "autodesk-bim360.rfis",
}

/** Data of a legacy (HoloBuilder) integration connected to a project */
interface LegacyProjectIntegration {
  /** The project name as defined by the integration */
  projectName: string;

  /** The project Id as defined by the integration */
  projectId: string;
}

/** Data of the legacy (HoloBuilder) Autodesk integration connected to a project */
export interface AutodeskLegacyProjectIntegration
  extends LegacyProjectIntegration {
  /** Id of the hub given by Autodesk */
  hubId: string;

  /** Autodesk integration ID */
  providerId: SphereDashboardAPITypes.IntegrationId.autodesk;
}

/** Data of the legacy (HoloBuilder) Procore integration connected to a project */
export interface ProcoreLegacyProjectIntegration
  extends LegacyProjectIntegration {
  /** Unique identifier of a company given by Procore */
  companyId: string;

  /** Procore integration ID */
  providerId: SphereDashboardAPITypes.IntegrationId.procore;
}

/** Data of an integration connected to a project */
export interface ProjectIntegration {
  /** ID of the BCF integration */
  bcfProjectId: string | null;
}

/** Integrations object of the ProjectApi root metadata */
export type ProjectIntegrationsMap = {
  /** Data of the legacy (HoloBuilder) Autodesk integration connected to a project */
  [ProjectIntegrationId.autodesk]?: AutodeskLegacyProjectIntegration | null;

  /** Data of the legacy (HoloBuilder) Procore integration connected to a project */
  [ProjectIntegrationId.procore]?: ProcoreLegacyProjectIntegration | null;

  /** Data of the ACC-issues integration connected to a project */
  [ProjectIntegrationId.autodeskAccIssues]?: ProjectIntegration | null;

  /** Data of the ACC-RFIs integration connected to a project */
  [ProjectIntegrationId.autodeskAccRfis]?: ProjectIntegration | null;

  /** Data of the Bim360-issues integration connected to a project */
  [ProjectIntegrationId.autodeskBim360Issues]?: ProjectIntegration | null;

  /** Data of the Bim360-RFIs integration connected to a project */
  [ProjectIntegrationId.autodeskBim360Rfis]?: ProjectIntegration | null;

  /** Data of the Procore-observations integration connected to a project */
  [ProjectIntegrationId.procoreObservations]?: ProjectIntegration | null;

  /** Data of the Procore-RFIs integration connected to a project */
  [ProjectIntegrationId.procoreRfis]?: ProjectIntegration | null;
};

/** Parsed message with the result of the integration authorization */
export interface AuthorizationMessage {
  /** Whether the connection was established. True if successful */
  // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by CoreAPI backend
  connectStatus: boolean;

  /** ID of the integration */
  providerId: SphereDashboardAPITypes.IntegrationId;

  /** Result status */
  status: "success" | "error";

  /** Human readable result message */
  message: string;
}

interface IntegrationSectionProps {
  /** The human readable name of an integration */
  displayName: string;

  /** The description of an integration */
  description: string;

  /** The icon of an integration */
  icon: FunctionComponent;
}

/** Different sections of each integration */
export const IntegrationSections: {
  [key in SphereDashboardAPITypes.IntegrationId]: IntegrationSectionProps;
} = {
  [SphereDashboardAPITypes.IntegrationId.autodesk]: {
    displayName: "Autodesk BIM 360",
    description:
      // eslint-disable-next-line max-len
      "Easily access BIM 360 docs to transfer files into your project. You can also create and view BIM 360 issues directly within the project.",
    icon: AutodeskIcon,
  },
  [SphereDashboardAPITypes.IntegrationId.procore]: {
    displayName: "Procore",
    description:
      // eslint-disable-next-line max-len
      "Access Procore documents and drawings to transfer files into your project. Create and view RFIs and observations directly within the platform.",
    icon: ProcoreIcon,
  },
};

/** All the actions that can be performed for integrations */
export type IntegrationActions =
  | "ConnectToWorkspace"
  | "DisconnectFromWorkspace"
  | "ConnectToProject"
  | "DisconnectFromProject";
