import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import {
  AddMembersToTeamProps,
  AddMembersToTeamResult,
  AddTeamProps,
  FetchTeamDetailsProps,
  FetchTeamMembers,
  FetchTeamMembersResponse,
  RemoveTeamMembersProps,
  RemoveTeamMembersResult,
} from "@store/teams/teams-slice-types";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  BaseCoreApiClientProps,
  CoreApiWithCompanyIdProps,
  CoreApiWithCompanyIdTeamIdProps,
} from "@store/store-types";
import { teamAdapter } from "@store/teams/teams-slice";
import { BaseTeamIdProps } from "@custom-types/sdb-company-types";

/** Fetches teams from the backend so they can be put into the store */
export const fetchTeams = createAsyncThunk<
  SphereDashboardAPITypes.ITeam[],
  CoreApiWithCompanyIdProps
>("teams/fetchTeams", async ({ coreApiClient, companyId }) => {
  try {
    const data = await coreApiClient.V3.SDB.fetchTeams(companyId);
    return data;
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});

/** Fetches the details of a team */
export const fetchTeamDetails = createAsyncThunk<
  SphereDashboardAPITypes.ITeam,
  FetchTeamDetailsProps
>("teams/fetchTeamDetails", async ({ coreApiClient, companyId, teamId }) => {
  try {
    const fetchedTeamDetails = await coreApiClient.V3.SDB.fetchTeamById(
      companyId,
      teamId
    );

    return fetchedTeamDetails;
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});

/** Creates a team in the backend and adds it to the store */
export const createTeam = createAsyncThunk<
  SphereDashboardAPITypes.ITeam,
  AddTeamProps
>(
  "teams/createTeam",
  async ({ coreApiClient, companyId, teamName, description }) => {
    try {
      const team = await coreApiClient.V3.SDB.createTeam(companyId, {
        name: teamName,
        description,
      });

      return team;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Fetches team members from the backend so they can be put into the store */
export const fetchTeamMembers = createAsyncThunk<
  FetchTeamMembersResponse,
  FetchTeamMembers
>(
  "teams/fetchTeamMembers",
  async ({ coreApiClient, companyId, teamId, next }) => {
    try {
      const result = await coreApiClient.V3.SDB.fetchTeamMembers({
        companyId,
        teamId,
        start: next ?? undefined,
      });

      return { teamId, members: result.data, next: result.next };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Invite members to a team */
export const addMembersToTeam = createAsyncThunk<
  AddMembersToTeamResult,
  AddMembersToTeamProps
>(
  "teams/addMembersToTeam",
  async ({ coreApiClient, companyId, teamId, members }) => {
    try {
      const addMembersResponse = await coreApiClient.V3.SDB.addMembersToTeam(
        companyId,
        teamId,
        { members }
      );

      return { ...addMembersResponse, teamId };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

interface UpdateTeamDetailsProps
  extends BaseCoreApiClientProps,
    BaseTeamIdProps {
  /** The payload for updating team details */
  payload: SphereDashboardAPITypes.IAddTeamToCompanyPayload
}

/** Update team details */
export const updateTeamDetails = createAsyncThunk<
  SphereDashboardAPITypes.ITeam,
  UpdateTeamDetailsProps,
  {
    state: RootState;
  }
>(
  "teams/updateTeamDetails",
  async ({ coreApiClient, teamId, payload }, { getState }) => {
    const { teams, sdbCompany  } = getState();

    /**
     * The ID of the team that is going to be updated.
     * If teamId is not provided, the selectedTeamId from store is selected
     */
    const updatingTeamId = teamId || teams.selectedTeamId;

    if (!sdbCompany.selectedSdbCompanyId) {
      throw new Error("No companyId exist to updateTeamDetails");
    }

    if (!updatingTeamId) {
      throw new Error("No teamId exist to updateTeamDetails");
    }

    const team = teamAdapter.getSelectors().selectById(teams, updatingTeamId);
    if (!team) {
      throw new Error("Team not found");
    }

    try {
      const updatedTeam = await coreApiClient.V3.SDB.updateTeam(
        sdbCompany.selectedSdbCompanyId,
        updatingTeamId,
        payload
      );

      return updatedTeam;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Remove a member from the team using the backend,
 * and then removes it from team in the store as well
 */
export const removeMembersFromTeam = createAsyncThunk<
  RemoveTeamMembersResult,
  RemoveTeamMembersProps
>(
  "teams/removeMembersFromTeam",
  async ({ coreApiClient, companyId, teamId, memberIds }) => {
    try {
      await coreApiClient.V3.SDB.removeMembersFromTeam(companyId, teamId, {
        members: memberIds,
      });

      return {
        teamId,
        companyId,
        memberIds,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Delete a team */
export const deleteTeam = createAsyncThunk<
  SphereDashboardAPITypes.TeamId,
  CoreApiWithCompanyIdTeamIdProps
>("teams/deleteTeam", async ({ coreApiClient, companyId, teamId }) => {
  try {
    await coreApiClient.V3.SDB.deleteTeam(companyId, teamId);
    return teamId;
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});
