import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import { TeamState } from "@custom-types/teams-types";
import { teamAdapter } from "@store/teams/teams-slice";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

/** Return the list of teams. */
export const teamsSelector: (
  state: RootState
) => SphereDashboardAPITypes.ITeam[] = createSelector(
  (state: RootState) => ({
    teams: teamAdapter.getSelectors().selectAll(state.teams),
    searchText: state.ui.search.searchText.toLowerCase(),
  }),
  ({ teams, searchText }) =>
    teams
      .filter((team) => team.name.toLowerCase().includes(searchText))
      .sort(
        (teamA, teamB) =>
          new Date(teamB.modifiedAt).getTime() -
          new Date(teamA.modifiedAt).getTime()
      )
);

/** Return the status of the endpoint fetchTeams */
export const fetchingStatusTeamsSelector: (state: RootState) => FetchingStatus =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.teams.status;
    }
  );

/**
 * Gets a team entity by providing the team ID
 */
export function getTeamByIdSelector(
  teamId: SphereDashboardAPITypes.TeamId | undefined
): (state: RootState) => SphereDashboardAPITypes.ITeam | undefined {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!teamId) {
        return undefined;
      }
      return teamAdapter.getSelectors().selectById(state.teams, teamId);
    }
  );
}

/**
 * Get the selected team using the selected team ID and finding it on the store,
 * returns null if there's no teamId or if the team could not be found in the store.
 */
export const selectedTeamSelector: (
  state: RootState
) => SphereDashboardAPITypes.ITeam | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const selectedTeamId = state.teams.selectedTeamId;
    if (!selectedTeamId) {
      return null;
    }

    return (
      teamAdapter.getSelectors().selectById(state.teams, selectedTeamId) ?? null
    );
  }
);

/**
 * Returns the fetching properties of the teams slice.
 */
export const fetchingTeamsFlagsSelector: (
  state: RootState
) => TeamState["fetching"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.teams.fetching;
  }
);

/**
 * Returns the members of the selected team.
 */
export const selectedTeamMembersSelector: (
  state: RootState
) => SphereDashboardAPITypes.ITeamMember[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return Object.values(state.teams.selectedTeamMembers);
  }
);

/**
 * Returns the next team member ID if set.
 */
export const nextTeamMemberSelector: (state: RootState) => string | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.teams.nextTeamMember;
    }
  );

/**
 * Returns whether the details panel is opened.
 */
export const selectIsDetailOpened: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.teams.isDetailOpened;
    }
  );
