import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { SphereDropzone } from "@components/common/sphere-dropzone/sphere-dropzone";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { Alert } from "@faro-lotv/flat-ui";
import { Grid, Stack } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { getFilesWithDuplicateNames, sortFiles } from "@utils/file-utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import UploadSvg from "@assets/icons/new/upload_50px.svg?react";
import {
  ElsScanFileUploadTaskContext,
  MultiUploadedFileResponse,
  UploadedFile,
  UploadElementType,
  UploadFailedFile,
  UploadMultipleFilesParams,
} from "@custom-types/file-upload-types";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { useAppSelector } from "@store/store-helper";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { ScanDataFile } from "@pages/project-details/project-data-management/import-data/scan-data-file";
import { ImportDataButton } from "@pages/project-details/project-data-management/import-data/import-data-button";
import { createRevisionForElsScans } from "@pages/project-details/project-data-management/import-data/create-revision-for-els-scans";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { getProjectApiClient } from "@api/project-api/project-api-utils";
import {
  addScansToRevisionAndMergeToMainHelper,
  ALLOWED_EXTENSIONS_ALL,
  ALLOWED_EXTENSIONS_GLS,
  AutoStartUpload,
  filesInfoForTracking,
  getScansAlreadyUploaded,
  isGLS,
  isValidGlsFile,
  lsDataV2InfoForTracking,
  MAX_FILE_SIZE_IN_MB,
} from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { RegistrationState } from "@faro-lotv/service-wires";
import { useFileUpload } from "@hooks/use-file-upload";
import { useToast } from "@hooks/use-toast";
import { FailedUploadsToastContent } from "@pages/project-details/project-data-management/import-data/failed-uploads-toast-content";
import { GUID } from "@faro-lotv/foundation";
import { ProjectApi } from "@api/project-api/project-api";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { LsDataV2Package, ReadLsDataV2Response } from "@api/stagingarea-api/stagingarea-api-types";
import { getLsDataV2Package, getScanByFilename } from "@api/stagingarea-api/stagingarea-api";
import { useStagingAreaApiClient } from "@api/stagingarea-api/use-stagingarea-api-client";

interface Props extends BaseProjectIdProps {
  uploadedIdsMap: { [key: string]: boolean };
  /** Flag whether the upload dialog is open. */
  isUploadDialogOpen: boolean;
  /** Setter for showing or hiding the upload dialog. */
  setIsUploadDialogOpen(isUploadDialogOpen: boolean): void;
  /** True to show the ImportDataButton. */
  willShowUploadButton: boolean;
}

/** Renders a button and the dialog to import ELS scan data. */
export function ImportData({
  projectId, uploadedIdsMap, isUploadDialogOpen, setIsUploadDialogOpen, willShowUploadButton,
}: Props): JSX.Element {
  const project = useAppSelector(selectedProjectSelector);
  const { handleErrorWithToast, handleErrorSilently } = useErrorContext();
  const { uploadMultipleFiles, validateAndAddFailedTask } = useFileUpload();
  const stagingAreaApi = useStagingAreaApiClient({ projectId });
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();

  /** The selected *.gls files to upload. */
  const [files, setFiles] = useState<File[]>([]);
  /** The selected scan metadata files, with validity information. */
  const [lsDataV2Files, setLsDataV2Files] = useState<LsDataV2Package | null>(null);
  /** The parsed scan metadata, if available. */
  const [lsDataV2, setLsDataV2] = useState<ReadLsDataV2Response | null>(null);
  /** True while creating the revision for the upload. */
  const [isCreatingRevision, setIsCreatingRevision] = useState<boolean>(false);

  /** Set to true to run useEffect() -> onConfirm() once after reading valid LsDataV2 metadata. */
  const autoStartUpload = useRef<AutoStartUpload | null>(null);

  const filesDuplicate = useMemo(() => {
    return getFilesWithDuplicateNames(files);
  }, [files]);

  const scansAlreadyUploaded = useMemo(() => {
    return getScansAlreadyUploaded(files, lsDataV2, uploadedIdsMap);
  }, [files, lsDataV2, uploadedIdsMap]);

  const haveAllScansMetadata = useMemo(() => {
    return !!lsDataV2 && files.every((file) => getScanByFilename(file.name, lsDataV2));
  }, [files, lsDataV2]);

  const isConfirmDisabled = useMemo(() => {
    if (!files.length) {
      return true;
    }

    // Check if there are any invalid files:
    const hasInvalidFile = files.some((file) => {
      return !isValidGlsFile(file, lsDataV2);
    });

    // If all scans were already uploaded before, we don't have anything to do.
    const hasOnlyExistingScans = files.every((file) => scansAlreadyUploaded.has(file));

    // Return false if the metadata is invalid, or if the metadata is not yet loaded.
    return hasInvalidFile || filesDuplicate.size > 0 || hasOnlyExistingScans ||
      (!!lsDataV2Files && !lsDataV2Files.isValid) ||
      (!!lsDataV2Files && !lsDataV2);
  }, [files, filesDuplicate, lsDataV2Files, lsDataV2, scansAlreadyUploaded]);

  /** Sets revision as canceled */
  const cancelRevision = useCallback(
    async (
      projectApiClient: ProjectApi,
      registrationRevisionId: GUID
    ): Promise<void> => {
      try {
        await projectApiClient.updateRegistrationRevision({
          registrationRevisionId,
          state: RegistrationState.canceled,
        });
      } catch (error) {
        // If it fails to set the revision as canceled only log the error but
        // don't show it to the user since this is not a critical step.
        handleErrorSilently({
          id: `updateRegistrationRevision-${Date.now().toString()}`,
          title: "Failed to set revision as canceled.",
          error,
        });
      }
    },
    [handleErrorSilently]
  );

  /** Handles the case when all uploads were canceled by the user */
  const handleAllUploadsCanceled = useCallback(
    async (
      projectApiClient: ProjectApi,
      registrationRevisionId: GUID
    ): Promise<void> => {
      showToast({
        message: "Cancelled all Blink scan imports.",
        type: "info",
        shouldAutoHide: true,
      });

      await cancelRevision(projectApiClient, registrationRevisionId);
    },
    [cancelRevision, showToast]
  );

  /** Handles the case when all uploads failed */
  const handleAllUploadsFailed = useCallback(
    async (
      projectApiClient: ProjectApi,
      registrationRevisionId: GUID
    ): Promise<void> => {
      showToast({
        message: "All Blink scan imports failed. Please try to upload them again.",
        type: "error",
      });

      await cancelRevision(projectApiClient, registrationRevisionId);
    },
    [cancelRevision, showToast]
  );

  /**
   * Handles the logic after the upload of ELS scan succeeded:
   * - Adds (creates) successful uploads to the specified cluster of the project revision.
   * - Updates revision to "registered" status and merges it to the main revision.
   * - If it fails it will then set the revision as canceled.
   */
  const addScansToRevisionAndMergeToMain = useCallback(
    async (
      successfulUploads: UploadedFile[],
      failedUploads: UploadFailedFile[],
      projectApiClient: ProjectApi,
      context: ElsScanFileUploadTaskContext
    ): Promise<void> => {
      try {
        await addScansToRevisionAndMergeToMainHelper(successfulUploads, projectApiClient, context);

        // Show a warning toast with the list of files that failed to upload
        if (failedUploads.length) {
          showToast({
            message: "Data import was partially successful",
            type: "warning",
            shouldAutoHide: false,
            description: (
              <FailedUploadsToastContent
                failedUploads={failedUploads}
                projectName={project?.name}
              />
            ),
          });
        }
      } catch (error) {
        handleErrorWithToast({
          id: `addScansToRevisionAndMergeToMain-${Date.now().toString()}`,
          title: "Failed to add imported data to project. Please try to import data again.",
          error,
        });

        // Attempt to set the revision as canceled
        await cancelRevision(projectApiClient, context.registrationRevisionId);
      }
    },
    [cancelRevision, handleErrorWithToast, project?.name, showToast]
  );

  const onUploadComplete = useCallback(
    async (
      uploadedResponse: MultiUploadedFileResponse,
      context: ElsScanFileUploadTaskContext
    ): Promise<void> => {
      const projectApiClient = getProjectApiClient({
        projectId: context.projectId,
      });

      const successfulUploads = uploadedResponse.successful;
      const failedUploads = uploadedResponse.failed;
      const canceledUploads = uploadedResponse.canceled;

      trackEvent({
        name: DataManagementEvents.finishUpload,
        props: {
          successfulUploads: successfulUploads.length,
          failedUploads: failedUploads.length,
          canceledUploads: canceledUploads.length,
        },
      });

      // Handle case when there are zero successful uploads
      if (!successfulUploads.length) {
        // If there are failed uploads consider it an all-failed case
        if (failedUploads.length) {
          return handleAllUploadsFailed(
            projectApiClient,
            context.registrationRevisionId
          );
        }

        // If there are zero failed uploads and some cancelled uploads consider it an all-cancelled case
        if (!failedUploads.length && canceledUploads.length) {
          return handleAllUploadsCanceled(
            projectApiClient,
            context.registrationRevisionId
          );
        }
      }

      // Handle case when there were successful uploads
      if (successfulUploads.length) {
        return addScansToRevisionAndMergeToMain(
          successfulUploads,
          failedUploads,
          projectApiClient,
          context
        );
      }
    },
    [
      addScansToRevisionAndMergeToMain,
      handleAllUploadsCanceled,
      handleAllUploadsFailed,
      trackEvent,
    ]
  );

  const initiateMultipleFileUpload = useCallback(
    async (
      registrationRevisionId: string,
      revisionClusterEntityId: string
    ): Promise<void> => {
      const context: ElsScanFileUploadTaskContext = {
        uploadElementType: UploadElementType.elsScan,
        projectId,
        registrationRevisionId,
        revisionClusterEntityId,
        lsDataV2,
      };

      // For the upload, we only consider files with the .gls extension.
      // So we shouldn't add errors for any other files.
      const uploadableFiles = files
        // Silently ignore already uploaded scans.
        .filter((file) => !scansAlreadyUploaded.has(file))
        // These conditions were already checked, except for the file size, but 20 GB is far enough for any *.fls|*.gls file.
        .filter((file) =>
          validateAndAddFailedTask({
            file,
            allowedExtensions: ALLOWED_EXTENSIONS_GLS,
            maxFileSize: MAX_FILE_SIZE_IN_MB,
            context,
          })
      );

      trackEvent({
        name: DataManagementEvents.startUpload,
        props: {
          filesGLS: files.length,
          filesGLSToUpload: uploadableFiles.length,
          filesGLSAlreadyUploaded: scansAlreadyUploaded.size,
          ...lsDataV2InfoForTracking(lsDataV2),
        },
      });

      // Return if there is no uploadable file
      if (!uploadableFiles.length) {
        return;
      }

      const uploadParams: UploadMultipleFilesParams = {
        files: uploadableFiles,
        onUploadStart: () => undefined,
        onUploadProgress: () => undefined,
        onUploadComplete,
        context,
      };

      await uploadMultipleFiles(uploadParams);
    },
    [
      files,
      lsDataV2,
      scansAlreadyUploaded,
      onUploadComplete,
      projectId,
      uploadMultipleFiles,
      validateAndAddFailedTask,
      trackEvent,
    ]
  );

  const closeDialog = useCallback(() => {
    setIsUploadDialogOpen(false);

    autoStartUpload.current = null;
    setFiles([]);
    setLsDataV2Files(null);
    setLsDataV2(null);
  }, [setIsUploadDialogOpen]);

  const onConfirm = useCallback(async (): Promise<void> => {
    autoStartUpload.current = null;
    setIsCreatingRevision(true);

    try {
      const { registrationRevisionId, revisionClusterEntityId } =
        await createRevisionForElsScans(projectId);

      initiateMultipleFileUpload(
        registrationRevisionId,
        revisionClusterEntityId
      );

      closeDialog();
    } catch (error) {
      handleErrorWithToast({
        id: `createRevisionForElsScans-${Date.now().toString()}`,
        title: "Failed to prepare a revision to import data. Please try again.",
        error,
      });
    }

    setIsCreatingRevision(false);
  }, [handleErrorWithToast, initiateMultipleFileUpload, projectId, closeDialog]);

  /** Auto-confirm the upload if the folder is valid, and each *.gls file is referenced in the LsDataV2 metadata. */
  useEffect(() => {
    if (isUploadDialogOpen && !isConfirmDisabled && haveAllScansMetadata && autoStartUpload.current === AutoStartUpload.start) {
      if (scansAlreadyUploaded.size > 0) {
        // If some selected scans were already uploaded before, better let the user double-check.
        autoStartUpload.current = null;
      } else {
        onConfirm();
      }
    }
  }, [
    files, lsDataV2, haveAllScansMetadata, scansAlreadyUploaded, isConfirmDisabled,
    isUploadDialogOpen, onConfirm, autoStartUpload,
  ]);

  /**
   * Try to read the LsDataV2 package, and enable the "Confirm" button if successful.
   * If the data of the selected folder appears fully consistent, `autoStartUpload` will automatically
   * start the upload.
   */
  function readLsDataV2Background(lsDataFiles: LsDataV2Package): void {
    autoStartUpload.current = AutoStartUpload.waiting;

    stagingAreaApi.postReadLsDataV2(lsDataFiles.files).then((lsData) => {
      setLsDataV2(lsData);
      // Only try to auto-confirm the upload if no files were added or removed in the meantime.
      if (autoStartUpload.current === AutoStartUpload.waiting) {
        autoStartUpload.current = AutoStartUpload.start;
      }
    }).catch((error) => {
      autoStartUpload.current = null;
      setLsDataV2Files({ ...lsDataFiles, isValid: false });
      setLsDataV2(null);
      handleErrorWithToast({
        id: `readLsDataV2-${Date.now().toString()}`,
        title: "Failed to read scan metadata. Pre-registration and scan names won't be used.",
        error,
      });
    });
  }

  /** Set the files for upload, replacing any previous selected ones. We allow to upload a single ELS folder at once. */
  function onSelectFiles(
    selectedFiles: FileList | File[],
    _: () => void
  ): void {
    autoStartUpload.current = null;
    selectedFiles = [...selectedFiles];
    const selectedGlsFiles = selectedFiles.filter((file) => isGLS(file.name));
    setFiles(sortFiles(selectedGlsFiles));

    const selectedLsDataV2Files = getLsDataV2Package(selectedFiles);
    setLsDataV2Files(selectedLsDataV2Files);
    setLsDataV2(null);
    if (selectedLsDataV2Files?.isValid) {
      readLsDataV2Background(selectedLsDataV2Files);
    }

    trackEvent({
      name: DataManagementEvents.selectFiles,
      props: filesInfoForTracking(selectedFiles),
    });
  }

  function removeFile(file: File): void {
    autoStartUpload.current = null;
    const allFiles = [...files];
    const index = allFiles.indexOf(file);
    if (index >= 0) {
      allFiles.splice(index, 1);
    }
    // When the last scan is removed, also remove the "Scan Metadata" UI element.
    if (!allFiles.length) {
      setLsDataV2Files(null);
      setLsDataV2(null);
    }
    setFiles(sortFiles(allFiles));
  }

  function removeMetaData(): void {
    autoStartUpload.current = null;
    setLsDataV2Files(null);
    setLsDataV2(null);
  }

  return (
    <>
      {willShowUploadButton && (
        <ImportDataButton onClick={() => setIsUploadDialogOpen(true)} />
      )}
      <FaroDialog
        title="Upload data"
        confirmText="Confirm"
        open={isUploadDialogOpen}
        onConfirm={onConfirm}
        isConfirmDisabled={isConfirmDisabled}
        isConfirmLoading={isCreatingRevision || (!!lsDataV2Files?.isValid && !lsDataV2)}
        onClose={closeDialog}
      >
        <Grid maxWidth="100%" width="70vw">
          <Alert
            title='For this Beta version you can only upload a folder with raw Blink data (.gls).
              Please upload the folder which contains "index-v2".'
            variant="info"
            sx={{
              marginBottom: SPACE_ELEMENTS_OF_MODAL,
              backgroundColor: sphereColors.blue100,
            }}
          />
          {(files.length > 0 && (!lsDataV2Files || (!!lsDataV2 && !haveAllScansMetadata))) &&
            <Alert
              title="Please upload a full Blink data folder to ensure the best registration results."
              variant="warning"
              sx={{
                marginBottom: SPACE_ELEMENTS_OF_MODAL,
                backgroundColor: sphereColors.yellow200,
              }}
            />
          }
          <Stack>
            <SphereDropzone
              instruction="Drag & drop"
              maxFileSize={MAX_FILE_SIZE_IN_MB}
              shouldShowSupportedFormats={false}
              shouldShowSizeLimit={false}
              shouldAllowMultiUpload={true}
              shouldAllowFolderUpload={true}
              shouldAllowFiles={false}
              avatar={
                <SphereAvatar
                  icon={<UploadSvg />}
                  size="x-large"
                  shouldHideWhiteRim
                  iconColor={sphereColors.gray600}
                  backgroundColor={sphereColors.gray100}
                />
              }
              allowedExtensions={ALLOWED_EXTENSIONS_ALL}
              isLoading={false}
              setIsLoading={() => undefined}
              onUploadComplete={onUploadComplete}
              context={{
                uploadElementType: UploadElementType.elsScan,
                projectId: projectId ?? "",
                registrationRevisionId: "",
                revisionClusterEntityId: "",
                lsDataV2,
              }}
              onSelectFiles={onSelectFiles}
            />
          </Stack>

          {(!!lsDataV2 || files.length > 0) && (
            <Stack
              sx={{
                my: SPACE_ELEMENTS_OF_MODAL,
                maxHeight: "200px",
                overflow: "auto",
              }}
            >
              {!!lsDataV2Files &&
                <ScanDataFile
                  fileTitle="Scan Metadata"
                  fileName="index-v2"
                  fileSize={lsDataV2Files.size}
                  onDelete={removeMetaData}
                  isValid={lsDataV2Files.isValid}
                />
              }
              {files.map((file, index) => (
                <ScanDataFile
                  key={index}
                  fileName={getScanByFilename(file.name, lsDataV2)?.name || file.name}
                  fileSize={file.size}
                  isExistingScan={scansAlreadyUploaded.has(file)}
                  onDelete={() => removeFile(file)}
                  isValid={isValidGlsFile(file, lsDataV2) && !filesDuplicate.has(file)}
                />
              ))}
            </Stack>
          )}
        </Grid>
      </FaroDialog>
    </>
  );
}
