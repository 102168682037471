import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { Grid, SvgIcon, Typography } from "@mui/material";
import { IntegrationSections } from "@services/integrations-service/integrations-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";

interface IntegrationDialogProps {
  /** Whether the dialog is open or not */
  isOpen: boolean;

  /** Callback when the dialog is confirmed */
  confirm: () => void | Promise<void>;

  /** Callback when the dialog is canceled */
  cancel?: () => void;
}

interface Props {
  /** Content for in progress integration */
  inProgressDialog: IntegrationDialogProps;

  /** Content for disable integration */
  disableDialog: IntegrationDialogProps;

  /** Selected integration ID */
  selectedIntegrationId: SphereDashboardAPITypes.IntegrationId;
}

/** All the dialogs for integrations process */
export function IntegrationDialog({
  selectedIntegrationId,
  inProgressDialog,
  disableDialog,
}: Props): JSX.Element | null {
  if (inProgressDialog.isOpen) {
    return (
      <FaroDialog
        open={true}
        onConfirm={inProgressDialog.confirm}
        confirmText="Cancel authorization"
        title=""
        isSuccessMessage
      >
        <Grid
          maxWidth="100%"
          width="600px"
          alignItems="center"
          container
          flexDirection="column"
          justifyContent="center"
          sx={{
            width: "612px",
            height: "556px",
          }}
        >
          <SvgIcon
            inheritViewBox={true}
            component={IntegrationSections[selectedIntegrationId].icon}
            sx={{ marginBottom: "30px", width: "95px", height: "95px" }}
          />
          <Typography
            sx={{
              fontSize: "20px",
              marginBottom: "30px",
            }}
          >
            {IntegrationSections[selectedIntegrationId].displayName}{" "}
            authorization
          </Typography>
          <Typography
            sx={{
              fontSize: DEFAULT_INPUT_FONT_SIZE,
              marginTop: "0px",
              marginBottom: "30px",
            }}
          >
            Please complete the authorization in the window that just opened.
          </Typography>
        </Grid>
      </FaroDialog>
    );
  }

  if (disableDialog.isOpen) {
    return (
      <FaroDialog
        open={true}
        onConfirm={disableDialog.confirm}
        onClose={disableDialog.cancel}
        confirmText="Disable"
        title="Disable integration?"
      >
        Disabling this integration will prevent you from using any of its
        functionality in any of your projects.
      </FaroDialog>
    );
  }

  return null;
}
